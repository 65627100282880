import React from 'react';
import { Box, Container, styled } from '@mui/material';
import Heading from './heading';

const Section = ({ children, title, ...rest }) => (
  <StyledSection component='section' {...rest}>
    <Container sx={{ maxWidth: '95rem !important' }}>
      {title && <Heading>{title}</Heading>}
      {children}
    </Container>
  </StyledSection>
);

export default Section;

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(15, 5, 0),

  '&:last-of-type': {
    paddingBottom: theme.spacing(15),
  },

  '&:first-of-type': {
    padding: theme.spacing(10, 5, 0),
  },

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(5, 1),

    '&:first-of-type': {
      padding: theme.spacing(12, 1, 5),
    },

    '&:last-of-type': {
      paddingBottom: theme.spacing(10),
    },
  },
}));
