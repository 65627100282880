import {
  fastSvg,
  ruggedSvg,
  uniqueSvg,
  iconicSvg,
  clockSvg,
  fairSvg,
  staffSvg,
  satisfactionSvg,
  Process1Svg,
  Process2Svg,
  Process3Svg,
  Process4Svg,
  Process5Svg,
} from 'components/svg';

import chevyBigImg from 'images/engines/chevy-big.png';
import chevySmallImg from 'images/engines/chevy-small.png';
import fordBigImg from 'images/engines/ford-big.png';
import fordFeImg from 'images/engines/ford-fe.png';
import fordSmallImg from 'images/engines/ford-small.png';
import lsImg from 'images/engines/ls.png';
import ltImg from 'images/engines/lt.png';
import moparBigImg from 'images/engines/mopar-big.png';
import moparHemiImg from 'images/engines/mopar-hemi.png';
import moparSmallImg from 'images/engines/mopar-small.png';
import generalEngineImg from 'images/engines/general-engine.svg';

import accessory1Img from 'images/accessories/accessories-1.png';
import accessory2Img from 'images/accessories/accessories-2.png';
import accessory3Img from 'images/accessories/accessories-3.png';
import accessory4Img from 'images/accessories/accessories-4.png';
import accessory5Img from 'images/accessories/accessories-5.png';
import accessory6Img from 'images/accessories/accessories-6.png';
import accessory7Img from 'images/accessories/accessories-7.png';
import accessory8Img from 'images/accessories/accessories-8.png';
import accessory9Img from 'images/accessories/accessories-9.png';
import accessory10Img from 'images/accessories/accessories-10.png';

import car1Img from 'images/cars/car-1.jpg';
import car2Img from 'images/cars/car-2.jpg';
import car3Img from 'images/cars/car-3.jpg';
import car4Img from 'images/cars/car-4.jpg';
import car5Img from 'images/cars/car-5.jpg';
import car6Img from 'images/cars/car-6.jpg';
import car7Img from 'images/cars/car-7.jpg';
import car8Img from 'images/cars/car-8.jpg';
import car9Img from 'images/cars/car-9.jpg';
import car10Img from 'images/cars/car-10.jpg';
import car11Img from 'images/cars/car-11.png';
import car12Img from 'images/cars/car-12.png';
import car13Img from 'images/cars/car-13.png';
import car14Img from 'images/cars/car-14.png';
import car15Img from 'images/cars/car-15.png';
import car16Img from 'images/cars/car-16.png';
import car17Img from 'images/cars/car-17.png';
import car18Img from 'images/cars/car-18.png';
import car19Img from 'images/cars/car-19.png';
import car20Img from 'images/cars/car-20.png';
import car21Img from 'images/cars/car-21.png';
import car22Img from 'images/cars/car-22.png';

import bike1Img from 'images/bikes/bike-1.jpg';
import bike2Img from 'images/bikes/bike-2.jpg';
import bike3Img from 'images/bikes/bike-3.jpg';
import bike4Img from 'images/bikes/bike-4.jpg';
import bike5Img from 'images/bikes/bike-5.jpg';
import bike6Img from 'images/bikes/bike-6.jpg';
import bike7Img from 'images/bikes/bike-7.jpg';
import bike8Img from 'images/bikes/bike-8.jpg';
import bike9Img from 'images/bikes/bike-9.jpg';
import bike10Img from 'images/bikes/bike-10.jpg';
import bike11Img from 'images/bikes/bike-11.jpg';
import bike12Img from 'images/bikes/bike-12.jpg';
import bike13Img from 'images/bikes/bike-13.jpg';
import bike14Img from 'images/bikes/bike-14.jpg';
import bike15Img from 'images/bikes/bike-15.jpg';
import bike16Img from 'images/bikes/bike-16.jpg';
import bike17Img from 'images/bikes/bike-17.jpg';
import bike18Img from 'images/bikes/bike-18.jpg';
import bike19Img from 'images/bikes/bike-19.jpg';
import bike20Img from 'images/bikes/bike-20.jpg';
import bike21Img from 'images/bikes/bike-21.jpg';

import miniCar1Img from 'images/minicars/minicar-1.png';
// import miniCar2Img from 'images/minicars/minicar-2.png';
import miniCar3Img from 'images/minicars/minicar-3.png';
import miniCar4Img from 'images/minicars/minicar-4.png';
import miniCar5Img from 'images/minicars/minicar-5.png';
import miniCar6Img from 'images/minicars/minicar-6.png';
import miniCar7Img from 'images/minicars/minicar-7.png';
import miniCar8Img from 'images/minicars/minicar-8.png';
// import miniCar9Img from 'images/minicars/minicar-9.png';
// import miniCar10Img from 'images/minicars/minicar-10.png';
// import miniCar11Img from 'images/minicars/minicar-11.png';
import miniCar12Img from 'images/minicars/minicar-12.png';
import miniCar13Img from 'images/minicars/minicar-13.png';
import miniCar14Img from 'images/minicars/minicar-14.png';
// import miniCar15Img from 'images/minicars/minicar-15.png';
import miniCar16Img from 'images/minicars/minicar-16.png';
import miniCar17Img from 'images/minicars/minicar-17.png';

export const navigationLinks = [
  {
    text: 'home',
    href: '/',
  },
  {
    text: 'about',
    href: '/about',
  },
  {
    text: 'active gear',
    href: '/accessories',
  },
  {
    text: 'exhilaRides',
    href: '/cars',
  },
  {
    text: 'bikes',
    href: '/bikes',
  },
  {
    text: 'miniature cars',
    href: '/minicars',
  },
  {
    text: 'engines',
    href: '/engines',
  },
];

export const aboutSlides = [
  {
    title: 'vision',
    text: "To be the leading creators of the most desirable cars possessing addictive performance that becomes a part of our customer's life story.",
  },
  {
    title: 'mission',
    text: 'To be more than a car company and a trusted partner for our customers and engage sincerely with them through attractive and distinctive products and services that deliver Enjoyment and Peace of mind',
  },
  {
    title: 'slogan',
    text: 'The well crafted art of performance and sophistication!',
  },
];

export const features = [
  {
    icon: fastSvg,
    text: 'fast',
  },
  {
    icon: ruggedSvg,
    text: 'rugged',
  },
  {
    icon: uniqueSvg,
    text: 'unique',
  },
  {
    icon: iconicSvg,
    text: 'iconic',
  },
];

export const whyUsPoints = [
  {
    icon: clockSvg,
    text: 'always available',
  },
  {
    icon: staffSvg,
    text: 'qualified staff',
  },
  {
    icon: fairSvg,
    text: 'fair prices',
  },
  {
    icon: satisfactionSvg,
    text: 'customer satisfaction',
  },
];

export const testimonials = [
  {
    review:
      'I was hesitant at first, but my husband encouraged me. But when I purchased the Dunkirk Edition from Phant, I can say that was the best decision I made. My family loves it for driving on the weekends and going to the beach. And I can surely say our neighbors admire our car.',
    reviewer: 'Sachi Jha',
    country: 'Germany',
  },
  {
    review:
      "The motors are built like a rock. I drove my Jonga from Navsari to Shimla and had not a single problem, even in the heat of daytime reaching as high as 42 degrees. I'm planning on getting myself a Willys build from them.",
    reviewer: 'Saurabh Gupta',
    country: 'India',
  },
  {
    review:
      "I bought my Jeep from them, being the first guy in Africa. That was a bold step. With the bad roads, I'm covered, and I cruise from my house to the farm with peace of mind. The suspension they make is made for Africa, I can confidently say.",
    reviewer: 'Ejay',
    country: 'Zimbabwe',
  },
  {
    review:
      'If you love to be noticed like me, you need a build from them. I have become a community celebrity.',
    reviewer: 'Adesh Narang',
    country: 'India',
  },
  {
    review:
      "It's not just a car, it's a Dunkirk. The Thar was seriously beautiful looking... and the engine... oh my goodness, one of the best engines I have driven. It can conquer any terrain. The variant I drove was the petrol automatic, which had a splendid powertrain.",
    reviewer: 'Shaun Mokoena',
    country: 'RSA',
  },
  {
    review:
      "This one is good, but if you are on a low budget, you should choose another option because this vehicle is primarily suited for off-road purposes. I don't recommend it for general use. However, if you enjoy off-roading and live in a rural area, it may be a suitable choice.",
    reviewer: 'Anurak Saetang',
    country: 'Thailand',
  },
  {
    review:
      "My experience with the consultation team was very good. I love the Thar for its ruggedness. However, the interior and comfort could be better. Perhaps it was because of my budget limitations. I have done off-roading with it, and it's superb in that aspect. The only drawback of this car is that the rear seats are not comfortable.",
    reviewer: 'Anant Bunmi',
    country: 'Thailand',
  },
  {
    review:
      "I'm not one for words, but I can confidently say, if your budget allows, go with them. These guys can build you anything you would be pleased with.",
    reviewer: 'Aditya Mishra',
    country: 'Canada',
  },
  {
    review:
      "The air suspension is mind-boggling. The consultation and purchase process was excellent. I bought a Honda with a Mugen kit, and it's breathtaking. I love how it stands out every time I park in the parking lot. Kudos to the Phant Wheelz team.",
    reviewer: 'Danny',
    country: 'United Kingdom',
  },
];

export const faqs = [
  {
    question: 'Do you provide after-purchase services?',
    answer:
      "On becoming a phant member you get two free services, and that's not the great part, Phant Wheelz supplies all original spare parts in service for our valued customers.",
  },
  {
    question: 'How do I know if Phant Wheelz is value for money?',
    answer: 'At Phant we provide different warranties on all our builds.',
  },
  {
    question: 'How can I place an order?',
    answer:
      '1. Talk to our consultation team to get a tailor made vehicle.\n2. Make a 30% deposit for the build.\n 3. Sign an order form.\n 4. Pickup your build on the date specified by our consultant.',
  },
  {
    question: 'How do I get exactly what I require on my build?',
    answer:
      'We have different experts in all the fields of the build with whom you can consult for free at any moment (before the design commences) to bring your ideas to life.',
  },
  {
    question: 'How fast can your builds go?',
    answer:
      'Simply speaking, our builds have three levels of speed. Here, There and Go! PS: We educate our customers over the dangers of over speeding if they choose a performance based build.😉',
  },
  {
    question: 'Will your builds attract admiration from people?',
    answer:
      'One thing our builds can surely do is to make you stand out and if our build does not create a stunning vibe around the people you drive then we are more than happy to give you your money back!',
  },
  {
    question:
      'How do I know if my performance motor can reach the mentioned horsepower and performance?',
    answer:
      'Our motors are tested on dynos and road. Plus off roading tests are conducted before a build gets Phant certified.',
  },
];

export const workflowSteps = [
  {
    title: 'get in touch',
    text: 'Fill out your contact details and choose mode of communication (Email / WhatsApp).',
    icon: Process1Svg,
  },
  {
    title: 'receive free consultation',
    text: 'You wil get free consultation for your requirements, styles and budget. We will make sure to personalise your vehicle with stunning styles and loads of utilities.',
    icon: Process2Svg,
  },
  {
    title: 'place your order',
    text: 'Choose the date you want us to begin and pay 30% deposit and sign the order form. (Dates are subject to availability of slots in our production department)',
    icon: Process3Svg,
  },
  {
    title: 'keep calm and meditate',
    text: 'It will take about 2 months for our craftsmanship to work on your vehicle and get it delivered to you. Your vehicle is unique as it is hand made!',
    icon: Process4Svg,
  },
  {
    title: 'make the road your catwalk',
    text: 'We will deliver your vehicle and you are ready to show it to the universe and make the road your Catwalk',
    icon: Process5Svg,
  },
];

export const buildPageSteps = [
  { label: 'contact information', icon: 'Phone' },
  { label: 'vehicle specifications', icon: 'Video' },
  { label: 'engine specifications', icon: 'Video' },
  { label: 'additional information', icon: 'Video' },
];

export const engines = [
  {
    title: 'chevy small block engine',
    description:
      'Custom Chevy small-block crate engines by Phant Wheel are designed around the best-known GM small-block, the 350. Our turn-key Chevy small blocks deliver performance and durability on a budget. Install one in your hot rod or cruiser and go crazy with aftermarket modifications.',
    hashtags: ['PhantWheelzEngines', 'BudgetPerformance', 'HotRodModifications'],
    image: chevySmallImg,
    isPerformanceEngine: true,
  },
  {
    title: 'chevy big block engine',
    description:
      'Want to add size, durability, and power to your ride? These Chevy Big Block large displacement V8 engines are perfect for powering trucks and heavier cars. Just drop in a custom turn-key Chevy Big Block and get ready to power up!',
    hashtags: ['BigBlockPower', 'HeavyHauler', 'TurnKeyPerformance'],
    image: chevyBigImg,
    isPerformanceEngine: true,
  },
  {
    title: 'ford big block engine',
    description:
      "When only a big, powerful, classic motor will do, a custom Ford big block is the way to go. Our Big-Block crate engines, inspired by Ford's classic 460 Big-Block, are stroked for added torque and improved overall performance. They're designed to meet heavy-duty on-street and off-road applications head-on.",
    hashtags: ['FordBigBlock', 'TorqueMonster', 'HeavyDutyPerformance'],
    image: fordBigImg,
    isPerformanceEngine: true,
  },
  {
    title: 'ford small block engine',
    description:
      "Our custom Ford Small Block crate engines, designed around Ford's 302, 351 Windsor, and Cleveland motors, are a Phant Wheelz specialty and our most popular builds. These turn-key crate engines deliver big power in a small package for incredible drivability.",
    hashtags: ['PhantWheelzSpecialty', 'SmallBlockPower', 'ClassicFordUpgrade'],
    image: fordSmallImg,
    isPerformanceEngine: true,
  },
  {
    title: 'ford FE engine',
    description:
      "Our Ford FE custom crate engine emulates the authentic Y-block design of yesteryear - the final 390 model, retired in 1971, to be exact. In our version, you'll find the highest quality aftermarket components incorporated into the Ford-inspired design for modern reliability despite the old- school styling.",
    hashtags: ['AuthenticYBlock', 'ModernReliability', 'FEPower'],
    image: fordFeImg,
    isPerformanceEngine: true,
  },
  {
    title: 'LS engine',
    description:
      "Our turn-key LS crate engine packages are the intersection of performance and reliability. Based on GM's legendary LS platform, our LS custom crate engines add low-end power and muscle-car sound to this line that provides an excellent platform for turbochargers.",
    hashtags: ['LSPerformance', 'ReliableMuscle', 'TurboReady'],
    image: lsImg,
    isPerformanceEngine: true,
  },
  {
    title: 'LT engine',
    description:
      'General Motors LT Series Engines utilize the latest technology to deliver imposing horsepower and impressive durability. Legends have said that nothing could outshine the remarkable LS engines, but they were wrong. There is a new King of the Hill.',
    hashtags: ['LTPerformance', 'DurabilityMatters', 'NewKingOfTheHill'],
    image: ltImg,
    isPerformanceEngine: true,
  },
  {
    title: 'mopar big block engine',
    description:
      "We've got the Mopar Big Block crate engine of your wildest dreams. Inspired by the legendary Mopar 426 Hemi, these turn- key power plants can withstand massive challenges and competition. When you're ready for a big, bad, beautiful powerhouse, this is the Mopar Big Block for you.",
    hashtags: ['MoparPowerhouse', 'Legendary426Hemi', 'TurnKeyPerformance'],
    image: moparBigImg,
    isPerformanceEngine: true,
  },
  {
    title: 'mopar gen III hemi engine',
    description:
      'The Gen I HEMI Engine steps up with modern technology and respect to the cylinder head design from the original 426 HEMI Engine to deliver the raw horsepower, epic torque and an adrenaline rush of sound. Yes, we make it better.',
    hashtags: ['ModernHEMI', 'RespectTheLegacy', 'BetterThanEver'],
    image: moparHemiImg,
    isPerformanceEngine: true,
  },
  {
    title: 'mopar small block engine',
    description:
      "Show the street you're serious by dropping a turn-key Mopar Small Block into your hot rod. These durable designs pack a huge punch and can infuse attitude into any car. We're excited to offer Small Block Mopar crate engines in several configurations using Chrysler's iconic 360 design.",
    hashtags: ['SeriousStreetMachine', 'DurablePerformance', 'Iconic360Design'],
    image: moparSmallImg,
    isPerformanceEngine: true,
  },
  {
    title: 'bolero turbo diesel engine',
    description:
      '<ul><li>Engine Displacement: 2523 cc</li><li>Cylinders: 4</li><li>Max Power: 76PS @ 3200rpm</li><li>Max Torque: 200Nm @ 1400-2200</li><li>2.5 Diesel Manual</li><li>Claimed FE 17.20kmpl</li></ul>',
    hashtags: ['EngineDisplacement', 'MaxPower', 'MaxTorque'],
    image: generalEngineImg,
    isPerformanceEngine: false,
  },

  {
    title: 'mHawk CRDe Diesel engine',
    description:
      '<ul><li>1997 cc, 4 Cylinders Inline, 4 Valves/Cylinder, DOHC</li><li>Engine Type: 4 cylinder mHawk CRDe diesel engine</li><li>Fuel Type: Diesel</li><li>Max Power (bhp@rpm): 120 bhp @ 4000 rpm</li><li>Max Torque (Nm@rpm): 280 Nm @ 1800 rpm</li><li>Mileage (ARAI): 15.4 kmpl</li><li>Turbocharger / Supercharger: Turbocharged</li></ul>',
    hashtags: ['BoleroTurboMileage', 'DieselManual', 'ClaimedFE'],
    image: generalEngineImg,
    isPerformanceEngine: false,
  },
  {
    title: 'toyota 3C',
    description:
      '<ul><li>LayoutStraight-4, vertical Fuel type Diesel</li><li>Displacement. 2.2 L, 2,184 cc (133.3 cu in) Injection Mechanical pump</li><li>Power adder. Turbocharger</li><li>Horsepower netFrom 88.0 PS (65.0 kW; 87.0 HP) at 4,000 грт to 91.0 PS (67.0 kW; 90.0 HP) at 4,000 rpmTorque outputFrom 188.0 N•m (19.2 kg.m, 138.6 ft Ib) at 1,800 г to 216.0 N-m (22 kg.m, 159.2 ft Ib) at 2,600 rpm</li><li>Firing order1-3-4-2</li></ul>',
    hashtags: ['DieselManual', 'FE17point20kmpl', 'mHawkCRDe'],
    image: generalEngineImg,
    isPerformanceEngine: false,
  },
  {
    title: 'toyota crysta engine',
    description:
      '<ul><li>2494 cc, 4Cylinders Inline, 4Valves/ Cylinder, SOHC</li><li>Engine Type: 4 cylinder Inline diesel engine</li><li>Fuel Type Diesel</li><li>Max Power (bhp@rpm) 101 bhp @ 3600 rpm</li><li>Max Torque (Nm@rpm) 200 Nm @ 1200 rpm</li><li>Turbocharger / Supercharger</li><li>Turbocharged</li></ul>',
    hashtags: ['PowerfulPerformance', 'PowerfulPerformance', 'TurbochargedThrills'],
    image: generalEngineImg,
    isPerformanceEngine: false,
  },
];

export const accessoryImages = [
  accessory1Img,
  accessory2Img,
  accessory3Img,
  accessory4Img,
  accessory5Img,
  accessory6Img,
  accessory7Img,
  accessory8Img,
  accessory9Img,
  accessory10Img,
];

export const carImages = [
  car1Img,
  car2Img,
  car3Img,
  car4Img,
  car5Img,
  car6Img,
  car7Img,
  car8Img,
  car9Img,
  car10Img,
  car11Img,
  car12Img,
  car13Img,
  car14Img,
  car15Img,
  car16Img,
  car17Img,
  car18Img,
  car19Img,
  car20Img,
  car21Img,
  car22Img,
];

export const bikeImages = [
  bike1Img,
  bike2Img,
  bike3Img,
  bike4Img,
  bike5Img,
  bike6Img,
  bike7Img,
  bike8Img,
  bike9Img,
  bike10Img,
  bike11Img,
  bike12Img,
  bike13Img,
  bike14Img,
  bike15Img,
  bike16Img,
  bike17Img,
  bike18Img,
  bike19Img,
  bike20Img,
  bike21Img,
];

export const miniCarImages = [
  miniCar1Img,
  miniCar3Img,
  miniCar4Img,
  miniCar5Img,
  miniCar6Img,
  miniCar7Img,
  miniCar8Img,
  miniCar12Img,
  miniCar13Img,
  miniCar14Img,
  miniCar16Img,
  miniCar17Img,
];
