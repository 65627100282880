import React from 'react';
import { Button, styled } from '@mui/material';

const ButtonWrapper = ({ children, loading, variant = 'primary', ...rest }) => {
  switch (variant) {
    case 'primary':
      return (
        <ButtonPrimary variant='contained' {...rest}>
          {children}
        </ButtonPrimary>
      );

    case 'secondary':
      return (
        <ButtonSecondary variant='outlined' {...rest}>
          {children}
        </ButtonSecondary>
      );

    default:
      return (
        <ButtonBase loading={loading ? 1 : 0} {...rest}>
          {children}
        </ButtonBase>
      );
  }
};

export default ButtonWrapper;

const ButtonBase = styled(Button)(({ theme, size }) => ({
  padding: size === 'small' ? theme.spacing(1, 3.5) : theme.spacing(1.5, 4),
  boxShadow: 'none',
  fontSize: size === 'small' ? '.9rem' : '1.25rem',
  fontWeight: 400,
  textTransform: 'capitalize',
  borderRadius: theme.borderRadius.large,
  position: 'relative',
  zIndex: 1,

  [theme.breakpoints.down('sm')]: {
    padding: size === 'small' ? theme.spacing(0.8, 2.5) : theme.spacing(1.5, 4),
    fontSize: size === 'small' ? '.8rem' : '1.25rem',
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'inherit',
    borderRadius: 'inherit',
    transition: 'all .3s',
    zIndex: -1,
  },

  '&:hover': {
    boxShadow: 'none',

    '&::before': {
      transform: 'scaleX(1.4) scaleY(1.6)',
      opacity: 0,
    },
  },
}));

const ButtonPrimary = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const ButtonSecondary = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  borderColor: theme.palette.secondary.main,

  '&:hover': {
    borderColor: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.dark,
  },
}));
