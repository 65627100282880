import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      light: '#dfe4f9',
      main: '#00abe0',
      dark: '#0080b7',
    },

    secondary: {
      main: '#b673f8',
    },

    neutral: {
      black: '#0e0f10',
      darkgrey: '#161b22',
      grey: '#e4e6f0',
      white: '#f7f8fc',
      lightgrey: '#a3b3bc',
    },
  },

  // https://stackoverflow.com/questions/52472372/responsive-typography-in-mui
  typography: {
    allVariants: {
      fontFamily: `'Poppins', sans-serif`,
    },
  },

  borderRadius: {
    tiny: '.7rem',
    small: '1.5rem',
    medium: '2.5rem',
    large: '6.25rem',
  },

  boxShadow: {
    light: '0 44px 24px rgba(237,239,241,.15)',
    dark: '0 1.5rem 3rem -.75rem rgba(0, 0, 0, .25)',
  },

  utils: {},
});

export default theme;
