exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessories-js": () => import("./../../../src/pages/accessories.js" /* webpackChunkName: "component---src-pages-accessories-js" */),
  "component---src-pages-bikes-js": () => import("./../../../src/pages/bikes.js" /* webpackChunkName: "component---src-pages-bikes-js" */),
  "component---src-pages-build-js": () => import("./../../../src/pages/build.js" /* webpackChunkName: "component---src-pages-build-js" */),
  "component---src-pages-cars-js": () => import("./../../../src/pages/cars.js" /* webpackChunkName: "component---src-pages-cars-js" */),
  "component---src-pages-engines-js": () => import("./../../../src/pages/engines.js" /* webpackChunkName: "component---src-pages-engines-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-minicars-js": () => import("./../../../src/pages/minicars.js" /* webpackChunkName: "component---src-pages-minicars-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

