import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Divider, Link, Stack, Typography, styled } from '@mui/material';

import { Grid, Heading, Icon, Section } from 'ui';
import logoImg from 'images/logo.png';

const Footer = () => (
  <Section component='footer' backgroundColor='neutral.darkgrey'>
    <FooterPartition>
      <SocialMedaiContainer>
        <Stack gap={2} direction='row' alignItems='center'>
          <FooterLogo src={logoImg} alt='LOGO' />
          <Heading variant='secondary' fontWeight={600}>
            Phant Wheelz
          </Heading>
        </Stack>
        <Stack gap={1} alignItems={{ xs: 'center', md: 'flex-start' }}>
          <Stack
            direction='row'
            alignItems='center'
            gap={1}
            component='a'
            href='https://www.google.com/maps?q=-17.7746702,30.9647405&z=17&hl=en'
            target='_blank'>
            <Icon name='MapPin' color='neutral.lightgrey' />
            <Typography>Westgate Harare</Typography>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            gap={1}
            component='a'
            href={`tel:${process.env.GATSBY_CONTACT_NUMBER}`}>
            <Icon name='Phone' color='neutral.lightgrey' />
            <Typography>{process.env.GATSBY_CONTACT_NUMBER}</Typography>
          </Stack>
        </Stack>

        <Stack direction='row' gap={2}>
          <SocalIcon
            href='https://www.instagram.com/phantwheelz/?igshid=MzNlNGNkZWQ4Mg%3D%3D'
            target='_blank'>
            <Icon color='grey.800' name='Instagram' />
          </SocalIcon>
          <SocalIcon href='https://www.youtube.com/@phantWheelz' target='_blank'>
            <Icon color='grey.800' name='Youtube' />
          </SocalIcon>
          <SocalIcon target='_blank'>
            <Icon color='grey.800' name='Twitter' />
          </SocalIcon>
          <SocalIcon target='_blank'>
            <Icon color='grey.800' name='Linkedin' />
          </SocalIcon>
        </Stack>
      </SocialMedaiContainer>

      <Grid
        justifyItems='center'
        textTransform='capitalize'
        color='grey.600'
        gap={10}
        responsive={false}
        sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
        <Stack gap={2} color='neutral.white'>
          <Typography variant='h6' fontWeight={600}>
            Links
          </Typography>

          <AnchorLink to='/'>home</AnchorLink>
          <AnchorLink to='/cars'>cars</AnchorLink>
          <AnchorLink to='/bikes'>bikes</AnchorLink>
          <AnchorLink to='/engines'>engines</AnchorLink>
        </Stack>

        <Stack gap={2} color='neutral.white'>
          <Typography variant='h6' fontWeight={600}>
            help
          </Typography>

          <a href={`mailto:${process.env.GATSBY_EMAIL_ADDRESS}`}>support</a>
          <AnchorLink to='/about'>about us</AnchorLink>
          <AnchorLink to='/#section-faq'>FAQ</AnchorLink>
          <AnchorLink to='/privacy-policy'>privacy</AnchorLink>
        </Stack>
      </Grid>
    </FooterPartition>

    <Divider sx={{ backgroundColor: 'neutral.lightgrey' }} />

    <FootNote>
      <Typography width={{ xs: '100%', md: '50%' }}>
        &copy; {new Date().getFullYear()} Copyright to all content presented on this website is
        owned by Phant Industries except where other sources are indicated. The content contained on
        this website shall not be reproduced without acknowledgement, as to the source.
      </Typography>

      <p>
        Developed by{' '}
        <Link target='_blank' color='inherit' href='https://aminpainter.netlify.app/'>
          Mohammed Amin Painter
        </Link>
        .
      </p>
    </FootNote>
  </Section>
);

export default Footer;

const FooterPartition = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(4),
  gap: theme.spacing(7),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const SocialMedaiContainer = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
  },
}));

const FooterLogo = styled('img')({
  width: '4rem',
});

const SocalIcon = styled('a')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.neutral.grey,
  padding: '.5rem',
  borderRadius: '100%',
  transition: 'transform .3s',

  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const FootNote = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 0),
  color: theme.palette.grey[500],
  gap: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));
