import React from 'react';
import { Typography, styled } from '@mui/material';

const Heading = ({ variant = 'primary', children, ...rest }) => {
  switch (variant) {
    case 'hero':
      return (
        <HeroTypography component='h1' variant='h1' {...rest}>
          {children}
        </HeroTypography>
      );

    case 'primary':
      return (
        <PrimaryTypography variant='h2' {...rest}>
          {children}
        </PrimaryTypography>
      );

    case 'secondary':
      return (
        <BaseTypography variant='h4' {...rest}>
          {children}
        </BaseTypography>
      );

    default:
      return (
        <BaseTypography variant='h6' {...rest}>
          {children}
        </BaseTypography>
      );
  }
};

export default Heading;

const BaseTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  color: 'transparent',
  backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main} 60%)`,
  WebkitBackgroundClip: 'text',
}));

const HeroTypography = styled(BaseTypography)(({ theme }) => ({
  fontSize: '5rem',
  fontWeight: 700,

  [theme.breakpoints.down('md')]: {
    fontSize: '3.5rem',
    textAlign: 'center',
  },
}));

const PrimaryTypography = styled(BaseTypography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '3rem',
  marginBottom: theme.spacing(4),
  textAlign: 'center',

  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
  },
}));
