import React from 'react';
import { ThemeProvider } from '@mui/material';
import { MotionConfig } from 'framer-motion';

import { theme } from 'ui';
import Layout from 'components/layout';
import 'styles/global.css';

const App = ({ children }) => (
  <MotionConfig
    transition={{
      duration: 0.7,
      type: 'spring',
      stiffness: 100,
    }}>
    <ThemeProvider theme={theme}>
      <Layout>{children}</Layout>
    </ThemeProvider>
  </MotionConfig>
);

export default App;
