import React from 'react';
import { MenuItem, TextField, styled } from '@mui/material';

const Input = ({ select, options = [], ...rest }) =>
  select ? (
    <StyledInput select {...rest}>
      {options.map(opt => (
        <MenuItem key={opt} value={opt} sx={{ textTransform: 'capitalize' }}>
          {opt}
        </MenuItem>
      ))}
    </StyledInput>
  ) : (
    <StyledInput {...rest} />
  );

export default Input;

const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: theme.palette.neutral.lightgrey,
  },
  '& label': {
    color: theme.palette.neutral.lightgrey,
  },
  '& .MuiOutlinedInput-root fieldset': {
    borderColor: theme.palette.neutral.lightgrey,
  },
  '&:hover .MuiOutlinedInput-root fieldset': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiSvgIcon-root ': {
    fill: theme.palette.neutral.lightgrey,
  },
}));
