import React, { useState } from 'react';
import { Link } from 'gatsby';
import {
  AppBar,
  Stack,
  styled,
  Typography,
  IconButton,
  Box,
  List,
  Drawer,
  ListItem,
} from '@mui/material';

import { navigationLinks } from 'utils/data';
import { Button, Icon } from 'ui';
import logoImg from 'images/logo.png';

const Navigation = () => {
  const [openMobileNav, setOpenMobileNav] = useState();
  const toggleMobileNav = visibility => () => setOpenMobileNav(visibility);

  return (
    <>
      <NavBar position='fixed'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Link to='/' style={{ display: 'flex' }}>
            <Logo src={logoImg} alt='LOGO' />
          </Link>

          <NavLinksContainer gap={3} direction='row'>
            {navigationLinks.map((navLink, idx) => (
              <NavLink
                key={idx}
                to={navLink.href}
                target={navLink.target || '_self'}
                activeClassName='nav-link-active'>
                <Typography variant='body1'>{navLink.text}</Typography>
              </NavLink>
            ))}
          </NavLinksContainer>

          <HighLightedButton
            component={Link}
            to='/build'
            endIcon={<Icon name='Settings' />}
            size='small'>
            Build my ExhilaRide
          </HighLightedButton>

          <Hamburger onClick={toggleMobileNav(true)}>
            <Icon name='Menu' color='primary.main' />
          </Hamburger>
        </Stack>
      </NavBar>

      <Drawer open={openMobileNav} anchor='top' onClose={toggleMobileNav(false)}>
        <MobileNav>
          <Stack alignItems='flex-end' px={2}>
            <IconButton onClick={toggleMobileNav(false)}>
              <Icon name='X' color='primary.main' />
            </IconButton>
          </Stack>

          <List>
            {navigationLinks.map((navLink, idx) => (
              <MobileNavItem key={idx}>
                <Link
                  to={navLink.href}
                  target={navLink.target || '_self'}
                  activeClassName='nav-link-active'
                  onClick={toggleMobileNav(false)}>
                  {navLink.text}
                </Link>
              </MobileNavItem>
            ))}
          </List>

          <Stack alignItems='center' mt={1}>
            <Button
              size='small'
              component={Link}
              to='/build'
              endIcon={<Icon name='Settings' />}
              onClick={toggleMobileNav(false)}>
              Build my ExhilaRide
            </Button>
          </Stack>
        </MobileNav>
      </Drawer>
    </>
  );
};

export default Navigation;

const NavBar = styled(AppBar)(({ theme }) => ({
  padding: `${theme.spacing(2, 5)} !important`,
  textTransform: 'capitalize',
  backgroundColor: 'rgba(14, 15, 16, 0.75)',
  backdropFilter: 'blur(16px) saturate(180%)',
  boxShadow: '0 1.5rem 3rem -.75rem rgba(0, 171, 224, .4)',
  color: theme.palette.neutral.lightgrey,

  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(2, 3)} !important`,
  },
}));

const Logo = styled('img')({
  height: '3rem',
  objectFit: 'contain',
});

const NavLinksContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const NavLink = styled(Link)(({ theme }) => ({
  position: 'relative',

  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.primary.main,
    bottom: '-.4rem',
    transform: 'scaleX(0)',
    transition: 'all .4s',
    transformOrigin: 'left',
    willChange: 'transform',
  },

  '&:hover::before': {
    transform: 'scaleX(1)',
  },
}));

const HighLightedButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Hamburger = styled(IconButton)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

const MobileNav = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  backgroundColor: theme.palette.neutral.black,
  color: theme.palette.neutral.lightgrey,
}));

const MobileNavItem = styled(ListItem)({
  display: 'flex',
  justifyContent: 'center',
  textTransform: 'capitalize',
});
