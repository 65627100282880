import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Box } from '@mui/material';

import MobileStepper from './mobile-stepper';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Carousel = ({ children, showControls = true, interval = 3000, ...rest }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = children?.length;

  const handleStepChange = step => {
    setActiveStep(step);
  };

  if (!maxSteps) return <></>;

  return (
    <Box sx={{ position: 'relative' }}>
      <AutoPlaySwipeableViews
        interval={interval}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents>
        {children.map((child, index) => (
          <Box style={{ padding: '3px' }} {...rest} key={index}>
            {Math.abs(activeStep - index) <= 2 ? child : null}
          </Box>
        ))}
      </AutoPlaySwipeableViews>

      {showControls && (
        <MobileStepper
          mt={2}
          steps={maxSteps}
          activeStep={activeStep}
          onStepClick={currentStep => setActiveStep(currentStep)}
        />
      )}
    </Box>
  );
};

export default Carousel;
