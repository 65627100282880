import React from 'react';

import Navigation from './navigation';
import Footer from './footer';
import { styled } from '@mui/material';

const Layout = ({ children }) => (
  <>
    <Navigation />
    <Main>{children}</Main>
    <Footer />
  </>
);

export default Layout;

const Main = styled('main')(({ theme }) => ({
  paddingTop: theme.spacing(10),
  minHeight: '100vh',

  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(2),
  },
}));
